<template>
  <div class="password-input">
    <b-input
      v-bind="$attrs"
      :type="typeOfInput"
      :state="state"
      :value="value"
      v-on="$listeners"
      @input="updateValue($event)"
    />
    <div id="icon-eye">
      <font-awesome-icon :icon="passwordIcon" @click="handleShowPassword" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ZPasswordInput",
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true
    },
    state: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      typeOfInput: "password",
      showPassword: false
    };
  },
  computed: {
    passwordIcon() {
      return this.showPassword ? "eye" : "eye-slash";
    }
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
    handleShowPassword() {
      this.showPassword = !this.showPassword;
      this.typeOfInput = this.showPassword ? "text" : "password";
    }
  }
};
</script>

<style lang="scss" scoped>
.password-input {
  display: flex;
  align-items: center;
  cursor: text;
  background-color: $white;
  border: 1px solid $gainsboro;
  border-radius: 0.25rem;

  input {
    cursor: text;
    color: $nero;
    min-height: 32px;
    border-color: transparent;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  #icon-eye {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $nobel;
    width: 3em;
    cursor: pointer;
  }
}
</style>
