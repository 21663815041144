<template>
  <footer v-if="isZubut">
    <div class="links">
      <a data-test-id="register" href="https://zubut.com/envios" target="_blank"
        >Registra tu empresa
      </a>
      <a data-test-id="fullfilment" href="https://zubut.com" target="_blank"
        >Fullfilment</a
      >
    </div>
    <div class="copy">
      &#169; 2022 Delivering Geniality SAPI de CV
    </div>
  </footer>
</template>

<script>
export default {
  name: "LoginFooter",

  computed: {
    isZubut() {
      return this.$store.getters["isZubut"];
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  color: #212529;
  &:hover {
    color: #0056b3;
  }
}
.links {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 12px;
}

.copy {
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  color: $comet;
}

@media (max-width: 480px) {
  .links {
    flex-direction: column;
    align-items: center;
  }
}
</style>
