<template>
  <login-wrapper>
    <template v-slot:header>
      <div class="z-general-message">
        <quick-message
          with-icon
          :show.sync="hasGeneralMessage"
          :message="generalMessage"
          :type="typeOfGeneralMessage"
        />
      </div>
      <zubut-image />
    </template>

    <template v-slot:section>
      <div class="title">Bienvenido a {{ companyName }}</div>

      <b-input
        id="email"
        v-model="login.email"
        type="text"
        class="form-control mb-3"
        placeholder="Correo electrónico"
      />
      <z-password-input
        v-model="login.password"
        class="mb-3"
        placeholder="Contraseña"
        @keyup.enter="handleLogin()"
      />

      <quick-message
        class="mb-3"
        :with-icon="withIcon"
        :show="showError"
        :timeout="messageTimeout"
        :message="message"
        :type="typeOfMessage"
      />

      <router-link
        :to="{ name: 'forgot' }"
        class="mb-5"
        data-test-id="recover-password"
      >
        ¿Olvidaste tu contraseña?
      </router-link>

      <z-button
        class="z-action"
        expanded
        :disabled="loading"
        :loading="loading"
        @click="handleLogin()"
      >
        {{ buttonMessage }}
      </z-button>
    </template>

    <template v-slot:footer>
      <login-footer />
    </template>
  </login-wrapper>
</template>

<script>
import DisconnectActions from "@/constants/users/disconnect-actions";
import LoginFooter from "./LoginFooter";
import LoginWrapper from "./LoginWrapper";
import ZubutImage from "./ZubutImage";
import ZPasswordInput from "@/app/components/ZPasswordInput";

export default {
  name: "Login",

  components: { LoginFooter, LoginWrapper, ZubutImage, ZPasswordInput },

  data() {
    return {
      loading: false,
      // This data is for login message
      message: "",
      showError: false,
      messageTimeout: false,
      typeOfMessage: "error",
      withIcon: false,
      // This data is for general message
      isSuccesful: false,
      hasGeneralError: false,
      generalMessage: "",
      typeOfGeneralMessage: "success",
      login: {
        email: "",
        password: ""
      },
      messages: {
        invalidData:
          "Por favor, revisa tu correo y/o contraseña e intenta de nuevo"
      }
    };
  },

  computed: {
    buttonMessage() {
      return this.loading ? "Cargando" : "Iniciar sesión";
    },
    hasGeneralMessage: {
      get() {
        return this.isSuccesful || this.hasGeneralError;
      },
      set(newVal) {
        this.isSuccesful = newVal;
        this.hasGeneralError = newVal;
      }
    },
    companyName() {
      return this.$store.getters["companyName"];
    }
  },

  mounted() {
    if (this.$route.query.t != null) {
      this.handleMessage(parseInt(this.$route.query.t));
    }
  },

  methods: {
    handleLogin() {
      const password = this.login.password;
      const email = this.login.email.trim();
      if (email && password) {
        this.loading = true;
        this.$store
          .dispatch("auth/login", { email, password })
          .then(res => {
            this.$store
              .dispatch("user/loadAccount", res.userId)
              .then(() => {
                this.$router.push({ name: "dashboard" });
              })
              .catch(err => {
                this.$captureError(err);
              });
          })
          .catch(err => {
            this.$captureError(err);
            this.handleError(err);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        const { invalidData } = this.messages;
        this.showError = true;
        this.typeOfMessage = "info";
        this.message = invalidData;
      }
    },

    handleError(error) {
      const { invalidData } = this.messages;

      this.showError = true;

      if (this.isWarningError(error)) {
        this.message = invalidData;
        this.typeOfMessage = "error";
      } else if (this.isDangerError(error)) {
        this.typeOfMessage = "error";
        this.message = error.message;
      } else if (this.isHarmlessError(error)) {
        this.typeOfMessage = "error";
        this.message = error.message;
      } else {
        this.typeOfMessage = "error";
        this.message = error.message;
      }
    },

    isDangerError(error) {
      return (
        error.status === 400 ||
        error.statusCode === 400 ||
        error.status === 403 ||
        error.statusCode === 403
      );
    },

    isWarningError(error) {
      return (
        error.code === "USERNAME_EMAIL_REQUIRED" ||
        error.code === "LOGIN_FAILED"
      );
    },

    isHarmlessError(error) {
      return error.status === 404 || error.statusCode === 404;
    },

    handleMessage(type) {
      switch (type) {
        case DisconnectActions.NUM_UPDATE_PASSWORD:
          this.isSuccesful = true;
          this.generalMessage = "Tu contraseña se actualizó con éxito";
          this.typeOfGeneralMessage = "success";
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.z-general-message {
  position: absolute;
  top: 25px;
  background-color: $white;
}
.z-action {
  height: 40px;
  font-weight: 600;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1em;
}

a {
  display: inline-flex;
}

.form-control {
  color: $nero;
}
</style>
