<template>
  <img v-if="isZubut" class="zubut" src="@/assets/img/zubut-blue.png" />
  <img v-else class="mu" src="@/assets/img/mu-logo_357x574.png" />
</template>

<script>
// TODO: Rename component
export default {
  name: "ZubutImage",

  computed: {
    isZubut() {
      return this.$store.getters["isZubut"];
    }
  }
};
</script>

<style lang="scss" scoped>
img.zubut {
  width: 50px;
}

img.mu {
  height: 72px;
}
</style>
